import payload_plugin_7Cbsd0p3L6 from "/Users/adria/FSBD/Projects-Hub/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_Frd8gASYWa from "/Users/adria/FSBD/Projects-Hub/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_ttziR7SnF4 from "/Users/adria/FSBD/Projects-Hub/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_3Bi0E2Ktsf from "/Users/adria/FSBD/Projects-Hub/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/Users/adria/FSBD/Projects-Hub/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "/Users/adria/FSBD/Projects-Hub/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JA9PnUIOcc from "/Users/adria/FSBD/Projects-Hub/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/Users/adria/FSBD/Projects-Hub/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kCUITUWXUN from "/Users/adria/FSBD/Projects-Hub/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/adria/FSBD/Projects-Hub/app-saas/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/Users/adria/FSBD/Projects-Hub/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_VwJ8VJpcHU from "/Users/adria/FSBD/Projects-Hub/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import plugin_PIdCykQ0f0 from "/Users/adria/FSBD/Projects-Hub/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_TeNxD8yYJh from "/Users/adria/FSBD/Projects-Hub/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/Users/adria/FSBD/Projects-Hub/app-saas/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/Users/adria/FSBD/Projects-Hub/app-saas/.nuxt/vuefire-plugin.mjs";
import plugin_client_lNtgiZdV49 from "/Users/adria/FSBD/Projects-Hub/node_modules/nuxt-vuefire/dist/runtime/app-check/plugin.client.js";
import directives_S0HTD52rJh from "/Users/adria/FSBD/Projects-Hub/app/plugins/directives.ts";
import sentry_client_BOObQ5G71Q from "/Users/adria/FSBD/Projects-Hub/app/plugins/sentry.client.ts";
import toastify_U399lLeOkf from "/Users/adria/FSBD/Projects-Hub/app/plugins/toastify.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/Users/adria/FSBD/Projects-Hub/app-saas/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_7Cbsd0p3L6,
  revive_payload_client_Frd8gASYWa,
  vuetify_icons_ttziR7SnF4,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  navigation_repaint_client_JA9PnUIOcc,
  check_outdated_build_client_yxgjJ9XxnV,
  chunk_reload_client_kCUITUWXUN,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  vuetify_no_client_hints_VwJ8VJpcHU,
  plugin_PIdCykQ0f0,
  plugin_client_TeNxD8yYJh,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  plugin_client_lNtgiZdV49,
  directives_S0HTD52rJh,
  sentry_client_BOObQ5G71Q,
  toastify_U399lLeOkf,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]